import React from 'react'
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import PageTemplate from "../components/page-template/page-template"

const content =
  <div>
    <p>We will review your message and get back to you shortly.</p>
  </div>


const Success = () => (
  <Layout>
    <SEO
      title="Wexler Law Group PLLC"
      description="Form Success"
    />
    <PageTemplate
      classname="Success"
      h1="Thank you for getting in touch."
      content={content}
    />
  </Layout>
)

export default Success
