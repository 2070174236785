import React from 'react'
import "./page-template.scss"

import SidebarForm from "../sidebar-form/sidebar-form"

const PageTemplate = (props) => (
  <div className="PageTemplate">
    <div className={`${props.classname} border-bottom`}>
      <div className="container padding-top-bottom">
        <div className="row">
          <div className="col-md-8">
            <div className="padding">
              <h1>{props.h1}</h1>
            </div>
            {props.content}
          </div>
          <div className="col-md-4">
            <SidebarForm/>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default PageTemplate